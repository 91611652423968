import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import deepEqual from 'fast-deep-equal';
import { Formik } from 'formik';
import { push as routerPush } from 'connected-react-router';

import actions from 'sow/actions/pure';
import * as adminRequestActions from 'sow/actions/adminRequest';
import * as sowTypes from 'sow/types';
import * as currentUser from 'sow/selectors/currentUser';
import { fromPlanApp } from 'sow/store/selectors';
import { resourceUpdate, resourceDetailRead } from 'sow/store/helpers';
import LocationWorksheetsForm from 'sow/components/organisms/PlanAppLocationWorksheetsForm';
import Spinner from 'sow/components/atoms/Spinner';
import { locationOverviewRoute } from 'sow/routes';
import {
  formatMissingAnswerStructure,
  buildFormChangeList,
  formatChangeRequest,
  saveAsNewChangeRequests,
  clearRequestsForLocationWorksheets,
  clearLocationRequests,
} from 'sow/utils/worksheet';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  worksheets: fromPlanApp.locationQualifiedWorksheets(state, props),
  isPlanAppLocked: fromPlanApp.isPlanAppLocked(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  isCSStaff: currentUser.isCSStaff(state, props),
  userId: currentUser.id(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  initialFormValues: fromPlanApp.locationFormInitialValues(state, props),
  locationAnswersChangesMap: fromPlanApp.locationAnswersChangesMap(state, props),
  locationAnswersChangeList: fromPlanApp.locationAnswersChangeList(state, props),
  isDeleted: fromPlanApp.isDeletedLocation(state, props),
  requestList: state.adminRequests.locationRequests.list,
  isInspector: currentUser.isInspector(state, props),
});

const mapDispatchToProps = dispatch => ({
  routerPush,
  clearRequest: (orgId, planAppId, requestId) =>
    dispatch(adminRequestActions.clearAdminRequest(orgId, planAppId, requestId)),
});

function getAnswerValue(change, formValues) {
  const { worksheetId, questionId } = change;
  return R.path(['worksheets', worksheetId, 'answers', 'values', questionId], formValues);
}

function getMatrixAnswerValue(change, formValues) {
  const { worksheetId, matrixRowId, questionId } = change;
  return R.path(
    [
      'worksheets',
      worksheetId,
      'answers',
      'matrixRows',
      matrixRowId,
      'values',
      questionId,
    ],
    formValues,
  );
}

function areAnswersEqual(answer1, answer2) {
  // Sometimes the answers will be null AND undefined, which fails deepEqual
  if (R.isNil(answer1) && R.isNil(answer2)) {
    return true;
  }
  return deepEqual(answer1, answer2);
}

/** Returns a list of changes whose 'new' value has been updated by the form */
function buildUpdatedChangeList(changeMap, formValues) {
  let updatedChangeList = [];
  R.values(changeMap).forEach(change => {
    const originalNewAnswer = R.prop('new', change);
    const isMatrixRowChange = !R.isNil(change.matrixRowId);
    const formValue = isMatrixRowChange
      ? getMatrixAnswerValue(change, formValues)
      : getAnswerValue(change, formValues);

    if (!areAnswersEqual(originalNewAnswer, formValue)) {
      updatedChangeList.push(R.assoc('new', formValue, change));
    }
  });

  return updatedChangeList;
}

const mapResourceToProps = () => {
  // Update worksheets resource
  const getUpdateAnswersResource = (orgId, planAppId, locationId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/land/${locationId}/answers`,
      'locationAnswers',
    );

  const getUpdateChangesResource = (orgId, planAppId, changeRequestId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
      'changeRequestOverviewResp',
    );

  const getCreateChangeResource = (orgId, planAppId, changeRequestId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
      'changeRequestOverviewResp',
    );

  const getAcceptChangeResource = (orgId, planAppId, changeRequestId, changeId) =>
    resourceUpdate(
      `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes/${changeId}/accept`,
      'changeRequestOverviewResp',
    );

  const mapResourceDispatchToProps = (dispatch, ownProps) => {
    const {
      orgId,
      planAppId,
      changeRequestId,
      locationId,
      locationAnswersChangesMap,
      locationAnswersChangeList,
      isStateInitialApplication,
      changeRequestIsOpen,
      clearRequest,
      requestList,
      acceptChange,
      isCSStaff,
      saveChanges,
      saveWorksheetAnswers,
      isInspector,
      userId,
    } = ownProps;

    const toastSuccessAction = message => actions.shell.toast('success', message);

    const updateAnswersResource = getUpdateAnswersResource(orgId, planAppId, locationId);
    const updateChangesResource = getUpdateChangesResource(
      orgId,
      planAppId,
      changeRequestId,
    );
    const createChangesResource = getCreateChangeResource(
      orgId,
      planAppId,
      changeRequestId,
    );

    // Change reqest streamlining allowing admins to save and accept worksheet changes
    async function saveMyWorksheetAnswers(formValues, initialValues) {
      if (isStateInitialApplication) {
        await saveWorksheetAnswers({
          product_answers: formValues,
        });
      } else {
        const worksheets = formValues.answers.worksheets;
        const worksheetChanges = formValues.answersChanges
          ? formValues.answersChanges.worksheets
          : null;

        const changes = buildUpdatedChangeList(
          locationAnswersChangesMap,
          formValues.answersChanges,
        );

        const createChange = changes =>
          dispatch(createChangesResource.action(null, { changes }));

        const newChanges = worksheets;

        if (!R.isEmpty(changes)) {
          await saveChanges({ changes: changes });
        }

        if (!R.isNil(newChanges)) {
          for (const [worksheetId, worksheet] of Object.entries(newChanges)) {
            const emptyAnswers = formatMissingAnswerStructure({ answers: {} });
            const newAnswers = formatMissingAnswerStructure(worksheet);
            const previousAnswers =
              typeof initialValues.answers.worksheets != 'undefined'
                ? formatMissingAnswerStructure(
                    initialValues.answers.worksheets[worksheetId],
                  )
                : emptyAnswers;
            const changeRequestList = buildFormChangeList(
              previousAnswers,
              newAnswers,
              emptyAnswers,
              changes,
              locationAnswersChangeList,
            );

            if (R.not(R.isEmpty(changeRequestList))) {
              // updates new answers to change requests
              await saveAsNewChangeRequests(
                changeRequestList,
                previousAnswers,
                newAnswers,
                orgId,
                planAppId,
                locationId,
                worksheetId,
                changeRequestId,
                createChange,
                getAcceptChangeResource,
                dispatch,
                clearRequest,
                requestList,
                isCSStaff,
                locationAnswersChangesMap,
                userId,
              );
            }
          }
        }
      }
    }

    async function updateAnswers(formValues) {
      await dispatch(
        updateAnswersResource.action(null, {
          productAnswers: formValues,
        }),
      );
    }

    async function updateChanges(formValues) {
      const changes = buildUpdatedChangeList(
        locationAnswersChangesMap,
        formValues.answersChanges,
      );

      if (!changes.length) return; // change list length must be > 0
      return dispatch(updateChangesResource.action(null, { changes }));
    }

    async function createChange(formValues) {
      const changes = buildUpdatedChangeList(
        locationAnswersChangesMap,
        formValues.answersChanges,
      );

      if (!changes.length) return; // change list length must be > 0
      return dispatch(
        createChangesResource.action(null, {
          changes,
        }),
      );
    }

    return {
      redirect: route => dispatch(routerPush(route)),
      onSubmit: async (formValues, initialValues) => {
        if (!isCSStaff) {
          clearLocationRequests(
            orgId,
            planAppId,
            formValues,
            initialValues,
            requestList,
            clearRequest,
            locationId,
          );
        }
        await saveMyWorksheetAnswers(formValues, initialValues);
        return dispatch(toastSuccessAction('Location answers saved.'));
      },
      clearRequest: (orgId, planAppId, requestId) =>
        dispatch(adminRequestActions.clearAdminRequest(orgId, planAppId, requestId)),
      acceptChange: (orgId, planAppId, changeRequestId, changeId) =>
        dispatch(acceptChangeResource(orgId, planAppId, changeRequestId, changeId)),
    };
  };

  return connect(null, mapResourceDispatchToProps);
};

class LocationWorksheetsFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    this.formik = React.createRef();
  }

  componentDidMount() {
    waitForEl('.dataTable', 1, function() {
      $('.dataTable').DataTable({ pageLength: 50, destroy: true });
    });
  }

  componentDidUpdate(prevProps) {
    waitForEl('.dataTable', 1, function() {
      if ($('.dataTables_wrapper').length == 0) {
        $('.dataTable').DataTable({ pageLength: 50, destroy: true });
      }
    });
  }

  handleSubmitRedirect = event => {
    const { orgId, planAppId } = this.props;

    event.preventDefault();
    this.formik.current.setStatus({
      redirectTo: locationOverviewRoute(orgId, planAppId),
    });
    this.formik.current.submitForm();
  };

  handleSubmitStay = event => {
    event.preventDefault();
    this.formik.current.submitForm();
  };

  handleSubmit = async (
    formikCurrent,
    values,
    formikBag,
    saveChanges,
    saveWorksheetAnswers,
  ) => {
    const { redirect, initialFormValues, onSubmit } = this.props;
    formikBag.setSubmitting(true);
    this.setState({ isLoading: true });

    try {
      await onSubmit(values, initialFormValues);
      const redirectTo = R.path(['state', 'status', 'redirectTo'], formikCurrent);

      formikBag.setSubmitting(false);

      this.setState({ isLoading: false });

      if (redirectTo) {
        redirect(redirectTo);
      }
      window.onbeforeunload = null;
    } catch (err) {
      this.setState({ isLoading: false });
      console.error('Error saving form:', err);
      formikBag.setSubmitting(false);
    }
  };

  render() {
    const {
      orgId,
      planAppId,
      isPlanAppLocked,
      isStateInitialApplication,
      changeRequestIsOpen,
      initialFormValues,
      worksheets,
      isDeleted,
      loadPlanApp,
      saveChanges,
      clearRequest,
      saveWorksheetAnswers,
      requestList,
    } = this.props;
    if (this.state.isLoading) return <Spinner />;
    return (
      <Formik
        ref={this.formik}
        onSubmit={(values, formikBag) => {
          this.handleSubmit(
            this.formik.current,
            values,
            formikBag,
            saveChanges,
            saveWorksheetAnswers,
          );
        }}
        initialValues={initialFormValues}
        enableReinitialize
      >
        {({ values, isSubmitting, dirty }) => (
          <LocationWorksheetsForm
            orgId={orgId}
            planAppId={planAppId}
            worksheets={worksheets}
            values={values}
            isSubmitting={isSubmitting}
            onSubmit={this.handleSubmitStay}
            onSubmitRedirect={this.handleSubmitRedirect}
            isPlanAppLocked={isPlanAppLocked}
            isStateInitialApplication={isStateInitialApplication}
            changeRequestIsOpen={changeRequestIsOpen}
            isDeleted={isDeleted}
            requestList={requestList}
            isDirty={dirty}
          />
        )}
      </Formik>
    );
  }
}

LocationWorksheetsFormContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheets: PropTypes.arrayOf(sowTypes.planAppWorksheetType).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialFormValues: PropTypes.shape({
    answers: PropTypes.object,
    answersChanges: PropTypes.object,
  }).isRequired,
  isPlanAppLocked: PropTypes.bool.isRequired,
  isStateInitialApplication: PropTypes.bool.isRequired,
  changeRequestIsOpen: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  saveChanges: PropTypes.func,
  saveWorksheetAnswers: PropTypes.func,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  mapResourceToProps(),
)(LocationWorksheetsFormContainer);

import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import { ResourceDetailLoader, resourceDetailRead } from 'sow/store/helpers';
import * as adminRequestActions from 'sow/actions/adminRequest';
import { fromPlanApp } from 'sow/store/selectors';
import * as currentOrg from 'sow/selectors/currentOrg';
import * as currentUser from 'sow/selectors/currentUser';
import OrgPlanAppLocationPage from 'sow/components/pages/OrgPlanAppLocationPage';
import {
  PlanAppLoader,
  PlanAppLocationListLoader,
  PlanAppWorksheetNotesLoader,
  PlanAppChangeRequestLoader,
  PlanAppChangeLoader,
} from 'sow/store/containers';
import { locationOverviewRoute } from 'sow/routes';
import ResourceLoaderStatusRender from 'sow/components/molecules/ResourceLoaderStatusRender';
import OrgPlanAppPageTemplate from 'sow/containers/OrgPlanAppPageTemplate';

const mapStateToProps = (state, props) => ({
  orgId: fromPlanApp.orgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  planAppLocation: fromPlanApp.planAppLocation(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  isStateUnderReview: fromPlanApp.isStateUnderReview(state, props),
  isPlanAppOpen: fromPlanApp.isPlanAppOpen(state, props),
  showOrgUI: currentUser.showOrgUI(state, props),
  editLocationByOrgAllowed: currentOrg.configPlanEditLocationByOrgAllowed(state, props),
  locationDetails: fromPlanApp.locationDetails(state, props),
  locationRequests: state.adminRequests.locationRequests,
  requestsFetching: state.adminRequests.locationRequests.isFetching,
  requestsLoaded: state.adminRequests.locationRequests.isLoaded,
  isClient: currentUser.isClient(state, props),
  isCSStaff: currentUser.isCSStaff(state, props),
});

const mapDispatchToProps = dispatch => {
  return {
    loadPlanApp: (orgId, planAppId, locationId) => {
      dispatch(
        resourceDetailRead(
          `org/${orgId}/application/${planAppId}/land/${locationId}/answers`,
          'planAppResp',
        ).action(),
      );
    },
    fetchLocationRequests: (orgId, planAppId) => {
      dispatch(adminRequestActions.fetchLocationRequests(orgId, planAppId));
    },
  };
};

const fakeStatus = status => ({
  ...status,
  error: false,
  done: true,
});

const OrgPlanAppLocationPageContainer = ({
  orgId,
  planAppId,
  locationId,
  planAppLocation,
  locationDetails,
  changeRequestId,
  isStateInitialApplication,
  isStateUnderReview,
  isPlanAppOpen,
  showOrgUI,
  editLocationByOrgAllowed,
  loadPlanApp,
  fetchLocationRequests,
  locationRequests,
  requestsFetching,
  requestsLoaded,
  isClient,
  isCSStaff,
}) => {
  // Loading location is only needed when id is positive, i.e. not a temp id created by change
  const autoloadLocation = locationId > 0;
  const isEditAllowed = editLocationByOrgAllowed || !showOrgUI || isClient || isCSStaff;

  const loadLocationRequests = () => {
    if (
      (!requestsLoaded && !requestsFetching) ||
      (requestsLoaded && locationRequests.planAppId != planAppId)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const loadRequests = loadLocationRequests();

  let answersReloading = false;

  return (
    <OrgPlanAppPageTemplate
      title={R.pathOr('', ['name'], locationDetails)}
      breadcrumb={[['Locations', locationOverviewRoute(orgId, planAppId)]]}
    >
      <PlanAppLoader autoLoad>
        {({ status: planAppStatus }) => (
          <PlanAppChangeRequestLoader autoLoad>
            {({ status: crStatus }) => (
              <PlanAppChangeLoader
                // does not work action="update"
                requestParams={{ land_ids: [locationId] }}
                autoLoad={!R.isNil(changeRequestId)}
              >
                {({ result, status: changesStatus }) => {
                  const areChangesLoaded = changeRequestId && !changesStatus.initial;
                  return (
                    <PlanAppChangeLoader action="update">
                      {({ updateResource: saveChanges }) => (
                        <ResourceLoaderStatusRender
                          statusObjects={[
                            areChangesLoaded ? changesStatus : fakeStatus(changesStatus),
                          ]}
                          renderAllDone={() => (
                            <PlanAppLocationListLoader autoLoad>
                              {({ status: locationListStatus }) => (
                                <ResourceDetailLoader
                                  resource={`org/${orgId}/application/${planAppId}/land/${locationId}`}
                                  entityType="planAppLocation"
                                  autoLoad={autoloadLocation}
                                >
                                  {({ status: locationStatus }) => (
                                    <ResourceDetailLoader
                                      resource={`org/${orgId}/application/${planAppId}/land/${locationId}/answers`}
                                      entityType="locationAnswers"
                                      autoLoad
                                    >
                                      {({
                                        status: answersStatus,
                                        updateResource: saveWorksheetAnswers,
                                      }) => {
                                        return (
                                          <ResourceLoaderStatusRender
                                            statusObjects={[
                                              planAppStatus,
                                              crStatus,
                                              locationListStatus,
                                              answersStatus,
                                              autoloadLocation
                                                ? locationStatus
                                                : fakeStatus(locationStatus),
                                            ]}
                                            renderAllDone={() => {
                                              if (
                                                areChangesLoaded &&
                                                loadRequests &&
                                                !requestsLoaded
                                              ) {
                                                fetchLocationRequests(orgId, planAppId);
                                              }
                                              return (
                                                <PlanAppWorksheetNotesLoader autoLoad>
                                                  {() => (
                                                    <OrgPlanAppLocationPage
                                                      orgId={orgId}
                                                      planAppId={planAppId}
                                                      locationId={locationId}
                                                      planAppLocation={planAppLocation}
                                                      changeRequestId={changeRequestId}
                                                      isStateInitialApplication={
                                                        isStateInitialApplication
                                                      }
                                                      isStateUnderReview={
                                                        isStateUnderReview
                                                      }
                                                      isPlanAppOpen={isPlanAppOpen}
                                                      isEditAllowed={isEditAllowed}
                                                      loadPlanApp={loadPlanApp}
                                                      locationRequests={locationRequests}
                                                      saveChanges={saveChanges}
                                                      saveWorksheetAnswers={
                                                        saveWorksheetAnswers
                                                      }
                                                    />
                                                  )}
                                                </PlanAppWorksheetNotesLoader>
                                              );
                                            }}
                                          />
                                        );
                                      }}
                                    </ResourceDetailLoader>
                                  )}
                                </ResourceDetailLoader>
                              )}
                            </PlanAppLocationListLoader>
                          )}
                        />
                      )}
                    </PlanAppChangeLoader>
                  );
                }}
              </PlanAppChangeLoader>
            )}
          </PlanAppChangeRequestLoader>
        )}
      </PlanAppLoader>
    </OrgPlanAppPageTemplate>
  );
};

OrgPlanAppLocationPageContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  locationId: sowTypes.planAppLocationIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  planAppLocation: sowTypes.planAppLocationType,
  locationDetails: sowTypes.planAppLocationType,
  isStateInitialApplication: PropTypes.bool.isRequired,
  isStateUnderReview: PropTypes.bool,
  isPlanAppOpen: PropTypes.bool.isRequired,
  showOrgUI: PropTypes.bool.isRequired,
  editLocationByOrgAllowed: PropTypes.bool.isRequired,
  loadPlanApp: PropTypes.func,
  isClient: PropTypes.bool.isRequired,
  isCSStaff: PropTypes.bool.isRequired,
};

OrgPlanAppLocationPageContainer.defaultProps = {
  planApp: null,
  planAppLocation: null,
  changeRequestId: null,
  locationchange: null,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(OrgPlanAppLocationPageContainer);

import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import deepEqual from 'fast-deep-equal';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push as routerPush } from 'connected-react-router';

import * as sowTypes from 'sow/types';
import actions from 'sow/actions/pure';
import * as adminRequestActions from 'sow/actions/adminRequest';
import { PlanAppWorksheetAnswersLoader, PlanAppChangeLoader } from 'sow/store/containers';
import { fromPlanApp, fromRouter } from 'sow/store/selectors';
import { resourceUpdate, resourceDetailRead } from 'sow/store/helpers';
import * as currentUser from 'sow/selectors/currentUser';
import { worksheetRoute, locationOverviewRoute } from 'sow/routes';
import PlanAppWorksheetForm from 'sow/components/organisms/PlanAppWorksheetForm';
import Spinner from 'sow/components/atoms/Spinner';

const getCreateChangeResource = (orgId, planAppId, changeRequestId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
    'changeRequestOverviewResp',
  );

const acceptChangeResource = (orgId, planAppId, changeRequestId, changeId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes/${changeId}/accept`,
    'changeRequestOverviewResp',
  );

const mapStateToProps = (state, props) => ({
  orgId: fromRouter.paramOrgId(state, props),
  planAppId: fromPlanApp.planAppId(state, props),
  worksheetId: fromPlanApp.worksheetId(state, props),
  locationId: fromPlanApp.locationId(state, props),
  matrixRowId: fromPlanApp.matrixRowId(state, props),
  changeRequestId: fromPlanApp.changeRequestId(state, props),
  nextWorksheetId: fromPlanApp.nextWorksheetId(state, props),
  prevWorksheetId: fromPlanApp.prevWorksheetId(state, props),
  changeList: fromPlanApp.worksheetChangeList(state, props),
  changeRequest: fromPlanApp.changeRequest(state, props),
  isCSStaff: currentUser.isCSStaff(state, props),
  showAcaUI: currentUser.showAcaUI(state, props),
  userId: currentUser.id(state, props),
  worksheet: fromPlanApp.worksheet(state, props),
  worksheetAnswers: fromPlanApp.worksheetAnswers(state, props),
  isPlanAppClosed: fromPlanApp.isPlanAppClosed(state, props),
  isPlanAppLocked: fromPlanApp.isPlanAppLocked(state, props),
  isStateInitialApplication: fromPlanApp.isStateInitialApplication(state, props),
  isStateUnderReview: fromPlanApp.isStateUnderReview(state, props),
  changeRequestIsOpen: fromPlanApp.changeRequestIsOpen(state, props),
  initialValues: fromPlanApp.formWorksheetInitialValues(state, props),
  isWorksheetNotApplicable: fromPlanApp.isWorksheetNotApplicable(state, props),
  worksheetQuestionList: fromPlanApp.worksheetQuestionList(state, props),
  requestList: state.adminRequests.worksheetRequests.list,
  isInspector: currentUser.isInspector(state, props),
});

const mapDispatchToProps = dispatch => {
  return {
    redirect: (...args) => dispatch(routerPush(...args)),
    loadPlanApp: (orgId, planAppId) =>
      dispatch(
        resourceDetailRead(
          `org/${orgId}/application/${planAppId}`,
          'planAppResp',
        ).action(),
      ),
    createChange: (action, changeObject) =>
      dispatch(action(null, { changes: changeObject })),
    acceptChange: action => dispatch(action(null)),
    clearRequest: (orgId, planAppId, requestId) =>
      dispatch(adminRequestActions.clearAdminRequest(orgId, planAppId, requestId)),
    toast: (...args) => dispatch(actions.shell.toast(...args)),
  };
};

class PlanAppWorksheetFormContainer extends React.Component {
  constructor() {
    super();
    this.state = { isLoading: false };
    this.formik = React.createRef();
  }

  componentDidMount() {
    $('.dataTable').DataTable({ pageLength: 50, destroy: true });
  }

  componentDidUpdate(prevProps, prevState) {
    waitForEl('.dataTable', 1, function() {
      if ($('.dataTables_wrapper').length == 0) {
        $('.dataTable').DataTable({ pageLength: 50, destroy: true });
      }
    });
  }

  formatChangeRequest = (questionId, matrixRowId, locationId, oldValue, newValue) => {
    const { planAppId, changeRequestId, worksheetId } = this.props;

    return {
      context: R.isNil(locationId) ? 'main' : 'land',
      type: R.isNil(matrixRowId) ? 'worksheet_answer' : 'matrix_row_answer',
      action: R.isNil(oldValue) ? 'added' : 'updated',
      applicationId: planAppId,
      changeRequestId: changeRequestId,
      worksheetId: worksheetId,
      questionId: questionId,
      matrixRowId: matrixRowId,
      landId: locationId,
      old: oldValue,
      new: newValue,
    };
  };

  formatMissingAnswerStructure(worksheetAnswers) {
    const answers = R.clone(worksheetAnswers);
    if (worksheetAnswers && R.has('answers', worksheetAnswers)) {
      if (!R.has('values', worksheetAnswers.answers)) {
        answers.answers.values = {};
      }
      if (!R.has('matrixRows', worksheetAnswers.answers)) {
        answers.answers.matrixRows = {};
      }
    } else if (R.isEmpty(worksheetAnswers)) {
      answers['answers'] = {};
      answers['answers']['values'] = {};
      answers['answers']['matrixRows'] = {};
    }
    return answers;
  }

  // currentChangeRequest is a map with current change values
  buildFormChangeList(currentAnswers, newAnswers, currentChangeRequests) {
    const hasUpdates = !deepEqual(currentAnswers, newAnswers);
    const valuesForMatrixRow = row => R.pathOr(null, ['values'], row);
    const hasValuesForMatrixRow = row => R.not(R.isNil(valuesForMatrixRow(row)));
    const answerForMatrixRow = (row, questionId) =>
      R.pathOr('no_match', ['values', questionId], row);
    const hasAnswerForMatrixRow = (row, questionId) =>
      answerForMatrixRow(row, questionId) === 'no_match';

    // function is available to buildFormChangeList only
    const removeQuestionsWithOpenChangeRequests = (
      currentAnswers,
      currentChangeRequests,
    ) => {
      const baseAnswers = R.clone(currentAnswers);
      const matrixRows = R.path(['matrixRows'])(currentChangeRequests);
      const changeValues = R.path(['values'])(currentChangeRequests);
      const hasMatrixRowChanges = R.not(R.isEmpty(matrixRows));
      const hasChangeValues = R.not(R.isEmpty(changeValues));

      if (R.not(R.isEmpty(baseAnswers))) {
        // remove row changes with existing change requests
        if (hasMatrixRowChanges) {
          for (var rowId in matrixRows) {
            const matrixValues = matrixRows[rowId]['values'];
            for (var questionId in matrixValues) {
              if (
                !hasAnswerForMatrixRow(
                  baseAnswers['answers']['matrixRows'][rowId],
                  questionId,
                ) &&
                baseAnswers['answers']['matrixRows'][rowId]
              ) {
                delete baseAnswers['answers']['matrixRows'][rowId]['values'][questionId];
                if (R.isEmpty(baseAnswers['answers']['matrixRows'][rowId]['values'])) {
                  delete baseAnswers['answers']['matrixRows'][rowId];
                }
              }
            }
          }
        }

        // remove changes with existing change requests
        if (hasChangeValues && R.not(R.isEmpty(baseAnswers))) {
          for (var questionId in changeValues) {
            if (R.has(questionId, baseAnswers['answers']['values'])) {
              delete baseAnswers['answers']['values'][questionId];
            }
          }
        }
      }

      return baseAnswers;
    };

    // mergeUpdatedAnswers function is available to buildFormChangeList only
    const mergeUpdatedAnswers = function(currentAnswers, newAnswers) {
      const mergedValues = R.clone(currentAnswers);
      const matrixRows = R.path(['answers', 'matrixRows'])(newAnswers);
      const changeValues = R.path(['answers', 'values'])(newAnswers);
      const isWorksheetMatrixRowChange = R.not(R.isEmpty(matrixRows));
      const hasChangeValues = R.not(R.isEmpty(changeValues));

      if (isWorksheetMatrixRowChange) {
        for (var rowId in matrixRows) {
          if (hasValuesForMatrixRow(matrixRows[rowId])) {
            const matrixValues = matrixRows[rowId]['values'];
            for (var answer in matrixValues) {
              if (
                R.isEmpty(mergedValues['answers']['matrixRows']) ||
                R.isNil(mergedValues['answers']['matrixRows'][rowId])
              ) {
                mergedValues['answers']['matrixRows'][rowId] = {};
                mergedValues['answers']['matrixRows'][rowId]['values'] = {};
              }
              mergedValues['answers']['matrixRows'][rowId]['values'][answer] =
                matrixValues[answer];
            }
          }
        }
      } else if (hasChangeValues) {
        for (var answer in changeValues) {
          mergedValues['answers']['values'][answer] = changeValues[answer];
        }
      }

      return mergedValues;
    };

    // deepDiffMapper function is available to buildFormChangeList only
    const deepDiffMapper = (function() {
      return {
        VALUE_CREATED: 'created',
        VALUE_UPDATED: 'updated',
        VALUE_DELETED: 'deleted',
        VALUE_UNCHANGED: 'unchanged',
        map: function(obj1, obj2) {
          if (this.isFunction(obj1) || this.isFunction(obj2)) {
            throw 'Invalid argument. Function given, object expected.';
          }

          if (this.isValue(obj1) || this.isValue(obj2)) {
            let type = this.compareValues(obj1, obj2);
            return {
              type: type,
              data: this.getChangedValue(obj1, obj2, type),
              old: obj1,
            };
          }

          if (
            this.isArray(obj1) &&
            this.isArray(obj2) &&
            typeof obj1[0] === 'object' &&
            typeof obj2[0] === 'object'
          ) {
            let type = this.compareValues(obj1, obj2);
            return {
              type: type,
              data: this.getChangedValue(obj1, obj2, type),
              old: obj1,
            };
          }

          var diff = {};

          for (var key in obj1) {
            if (this.isFunction(obj1[key])) {
              continue;
            }

            var value2 = undefined;
            if (obj2[key] !== undefined) {
              value2 = obj2[key];
            }

            diff[key] = this.map(obj1[key], value2);
          }

          for (var key in obj2) {
            if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
              continue;
            }

            diff[key] = this.map(undefined, obj2[key]);
          }

          return diff;
        },
        compareValues: function(value1, value2) {
          if (this.isArray(value1) && this.isArray(value2)) {
            if (JSON.stringify(value1) === JSON.stringify(value2)) {
              return this.VALUE_UNCHANGED;
            } else {
              return this.VALUE_UPDATED;
            }
          } else {
            if (value1 === value2) {
              return this.VALUE_UNCHANGED;
            }
            if (
              this.isDate(value1) &&
              this.isDate(value2) &&
              value1.getTime() === value2.getTime()
            ) {
              return this.VALUE_UNCHANGED;
            }
            if (value1 === undefined && value2 != null) {
              return this.VALUE_CREATED;
            }
            if (value2 === undefined && value1 != null) {
              return this.VALUE_DELETED;
            }
            if (
              (value1 === undefined && value2 == null) ||
              (value2 === undefined && value1 == null)
            ) {
              return this.VALUE_UNCHANGED;
            }
            return this.VALUE_UPDATED;
          }
        },
        getChangedValue: function(value1, value2, type) {
          let newVal = value1;
          if (type === this.VALUE_UPDATED) {
            newVal = value2;
          }
          if (type === this.VALUE_CREATED) {
            newVal = value2;
          }
          return newVal;
        },
        isFunction: function(x) {
          return Object.prototype.toString.call(x) === '[object Function]';
        },
        isArray: function(x) {
          return Object.prototype.toString.call(x) === '[object Array]';
        },
        isDate: function(x) {
          return Object.prototype.toString.call(x) === '[object Date]';
        },
        isObject: function(x) {
          return Object.prototype.toString.call(x) === '[object Object]';
        },
        isValue: function(x) {
          return !this.isObject(x) && !this.isArray(x);
        },
      };
    })();

    if (hasUpdates) {
      const baseAnswers = removeQuestionsWithOpenChangeRequests(
        currentAnswers,
        currentChangeRequests,
      );
      const updatedAnswers = removeQuestionsWithOpenChangeRequests(
        newAnswers,
        currentChangeRequests,
      );
      const diffChanges = deepDiffMapper.map(baseAnswers, updatedAnswers);
      return diffChanges;
    } else {
      return {};
    }
  } // end buildFormChangeList

  buildUpdatedChangeList(worksheetAnswersChanges) {
    const getNewValue = change => {
      if (change.matrixRowId) {
        return R.path(
          ['matrixRows', change.matrixRowId, 'values', change.questionId],
          worksheetAnswersChanges,
        );
      } else if (change.type === 'worksheet') {
        return {
          isNotApplicable: worksheetAnswersChanges.isNotApplicable,
        };
      }

      return R.path(['values', change.questionId], worksheetAnswersChanges);
    };

    /**
     * This function takes a change (entity) object, tests if the change's new
     * property is different than the current form's value (using a deep equal,
     * NOT reference check!) and returns a new change object for PUT'ing to the
     * endpoint to save te update. If the value is not changed `null` is
     * returned.
     */
    const newChangeOrNil = change => {
      const newValue = getNewValue(change);
      const isEqual = deepEqual(change.new, newValue);
      return isEqual ? null : { ...change, new: newValue };
    };

    const isWorksheetMatrixRowChange = R.pathEq(['type'], 'matrix_row');

    /**
     * Takes list of change objects and returns array of any change objects that
     * have updated `new` value.
     */
    const updatedChangeList = R.pipe(
      R.map(newChangeOrNil),
      R.reject(R.isNil),
      R.reject(isWorksheetMatrixRowChange),
    );

    return updatedChangeList(this.props.changeList);
  }

  onClickSubmit = e => {
    e.preventDefault();
    this.setNoRedirect();
    this.formik.current.submitForm();
  };

  onClickSubmitNext = e => {
    e.preventDefault();
    const { nextWorksheetId, worksheetId } = this.props;
    if (nextWorksheetId === worksheetId) {
      // last worksheet so next goes to overview
      this.setRedirectLocationOverview();
    } else {
      this.setRedirectToWorksheet(nextWorksheetId);
    }

    this.formik.current.submitForm();
  };

  onClickSubmitPrev = e => {
    e.preventDefault();
    this.setRedirectToWorksheet(this.props.prevWorksheetId);
    this.formik.current.submitForm();
  };

  handleSubmit = async (
    formikCurrent,
    values,
    formikBag,
    saveChanges,
    saveWorksheetAnswers,
  ) => {
    const { redirect, worksheetAnswers, initialValues } = this.props;
    try {
      this.setState({ isLoading: true });

      const redirectTo = R.path(['state', 'status', 'redirectTo'], formikCurrent);
      const currentAnswers = this.formatMissingAnswerStructure(worksheetAnswers);

      // save change request updates first
      await this.saveChangeRequestChanges(values, formikBag, saveChanges);

      // saved new values
      await this.saveAnswerChanges(
        values,
        currentAnswers,
        formikBag,
        saveWorksheetAnswers,
      );

      if (redirectTo) {
        formikBag.resetForm(initialValues);
        redirect(redirectTo);
      } else {
        formikBag.resetForm(initialValues);
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.error('PlanAppWorksheetForm ERRORS!!!', { error, values, status });
      formikBag.setStatus({ error: error.message });
      formikBag.setSubmitting(false);
      this.setState({ isLoading: false });
    }
  };

  async loadPlanApp() {
    const { orgId, planAppId, loadPlanApp } = this.props;
    return loadPlanApp(orgId, planAppId);
  }

  saveAsNewChangeRequests = async (changeList, oldAnswers, newAnswers) => {
    const {
      orgId,
      planAppId,
      changeRequestId,
      createChange,
      acceptChange,
      isCSStaff,
      isInspector,
      userId,
      worksheetId,
    } = this.props;

    const { action } = getCreateChangeResource(orgId, planAppId, changeRequestId);

    let matrixRows = R.path(['answers', 'matrixRows'])(changeList);
    let changeValues = R.path(['answers', 'values'])(changeList);

    const isWorksheetMatrixRowChange = R.not(R.isEmpty(matrixRows));
    const changes = [];
    const getMatrixAnswerValue = (answers, rowId, questionId) =>
      R.pathOr(null, ['answers', 'matrixRows', rowId, 'values', questionId], answers);
    const getAnswerValue = (answers, questionId) =>
      R.pathOr(null, ['answers', 'values', questionId], answers);
    const hasUpdatedVersionChange = change =>
      R.has('__version', change) && change.__version.type === 'updated';
    const hasUpdatedFileTypeChange = change =>
      (R.has('id', change) && R.has('name', change) && change.name.type === 'updated') ||
      (R.has('type', change) && (change.type === 'created' || change.type === 'deleted'));
    const hasChange = change =>
      (R.has('type', change) && change.type !== 'unchanged') ||
      hasUpdatedVersionChange(change);
    const isDeleted = change => hasChange(change) && change.type === 'deleted';
    const hasNestedChange = change => {
      if (R.has('0', change)) {
        // not an array, but zero key
        for (const [key, value] of Object.entries(change)) {
          if (typeof value != 'undefined') {
            if (hasChange(value) || hasUpdatedVersionChange(value)) {
              return true;
            }
          }
        }
      }
      if (R.has('__fieldType', change) && change.__fieldType.data === 'checkbox_list') {
        for (const [key, value] of Object.entries(change.checked)) {
          if (typeof value != 'undefined') {
            if (hasChange(value) || hasUpdatedVersionChange(value)) {
              return true;
            }
          }
        }
        for (const [key, value] of Object.entries(change.unchecked)) {
          if (typeof value != 'undefined') {
            if (hasChange(value) || hasUpdatedVersionChange(value)) {
              return true;
            }
          }
        }
      }
      /*if (Array.isArray(change) && change.length > 0) {
        change.map(subChange => {
          if (hasChange(subChange) || hasUpdatedFileTypeChange(subChange)) {
            return true;
          }
        });
      }*/
      return false;
    };

    const hasNestedFileTypeChange = change => {
      if (R.has('0', change)) {
        for (const [key, value] of Object.entries(change)) {
          if (hasUpdatedFileTypeChange(value)) {
            return true;
          }
        }
      }
      return false;
    };

    if (isWorksheetMatrixRowChange) {
      const matrixRowValues = R.has('data', matrixRows) ? matrixRows.data : matrixRows;

      for (var rowId in matrixRowValues) {
        // check for new row data
        if (matrixRowValues[rowId]) {
          // get newly created data.values
          if (
            R.has('data', matrixRowValues[rowId]) &&
            matrixRowValues[rowId]['type'] === 'created'
          ) {
            const newMatrixValues = matrixRowValues[rowId]['data']['values'];
            for (var questionId in newMatrixValues) {
              const newChange = newMatrixValues[questionId];
              if (newChange !== null) {
                const formattedChange = this.formatChangeRequest(
                  questionId,
                  rowId,
                  null,
                  null,
                  newChange,
                );
                changes.push(formattedChange);
              }
            }
          } else if (R.has('data', matrixRows) && matrixRows['type'] === 'created') {
            const newMatrixValues = matrixRowValues[rowId]['values'];
            for (var questionId in newMatrixValues) {
              const newChange = matrixRowValues[questionId];
              if (newChange !== null) {
                const formattedChange = this.formatChangeRequest(
                  questionId,
                  rowId,
                  null,
                  null,
                  newChange,
                );
                changes.push(formattedChange);
              }
            }
          }

          // has updated values
          const matrixValues = matrixRowValues[rowId]['values'];
          for (var questionId in matrixValues) {
            const change = matrixValues[questionId];
            if (change) {
              if (hasChange(change)) {
                const oldChange = getMatrixAnswerValue(oldAnswers, rowId, questionId);
                const newChange = getMatrixAnswerValue(newAnswers, rowId, questionId);
                if (isDeleted(change)) {
                  // Do not create an updated change for null > empty or empty > null
                  if (change.data.old !== '' && oldChange !== '') {
                    const formattedChange = this.formatChangeRequest(
                      questionId,
                      rowId,
                      null,
                      oldChange,
                      null,
                    );
                    changes.push(formattedChange);
                  }
                } else if (oldChange !== newChange) {
                  const formattedChange = this.formatChangeRequest(
                    questionId,
                    rowId,
                    null,
                    oldChange,
                    newChange,
                  );
                  changes.push(formattedChange);
                }
              }

              // Is there a file type change?
              if (hasNestedFileTypeChange(change)) {
                // Loop through the nested changes
                const arrValueUpdates = [];
                const arrValueExisting = [];
                for (const [key, value] of Object.entries(change)) {
                  // Is this a new or deleted file?
                  if (R.has('type', value)) {
                    // Is this a new file?
                    if (value.type === 'created') {
                      // Set the new value
                      arrValueUpdates.push({ id: value.data.id, name: value.data.name });
                    }

                    // Is this a deleted file?
                    if (value.type === 'deleted') {
                      // Set the new value
                      arrValueExisting.push({ id: value.data.id, name: value.data.name });
                    }
                  } else {
                    // Make sure an ID is present
                    if (R.has('id', value)) {
                      // Set the new value
                      arrValueUpdates.push({ id: value.id.data, name: value.name.data });

                      // Remember the old value
                      arrValueExisting.push({ id: value.id.old, name: value.name.old });
                    }
                  }
                }

                // Add the change
                const formattedChange = this.formatChangeRequest(
                  questionId,
                  rowId,
                  null,
                  arrValueExisting.length > 0 ? arrValueExisting : null,
                  arrValueUpdates.length > 0 ? arrValueUpdates : null,
                );
                changes.push(formattedChange);
              } else if (hasNestedChange(change)) {
                const nestedChange = change[0];
                if (isDeleted(nestedChange) && R.has('data', nestedChange)) {
                  const oldChange = nestedChange.data;
                  const formattedChange = this.formatChangeRequest(
                    questionId,
                    rowId,
                    null,
                    oldChange,
                    null,
                  );
                  changes.push(formattedChange);
                }
              }
            }
          }
        }
      }
    } else {
      for (var questionId in changeValues) {
        const change = changeValues[questionId];

        if (hasChange(change)) {
          const oldChange = getAnswerValue(oldAnswers, questionId);
          const newChange = getAnswerValue(newAnswers, questionId);
          if (oldChange !== newChange) {
            const formattedChange = this.formatChangeRequest(
              questionId,
              null,
              null,
              oldChange,
              newChange,
            );
            changes.push(formattedChange);
          }
        }

        if (hasNestedChange(change)) {
          const oldChange = getAnswerValue(oldAnswers, questionId);
          const newChange = getAnswerValue(newAnswers, questionId);
          if (oldChange !== newChange) {
            const formattedChange = this.formatChangeRequest(
              questionId,
              null,
              null,
              oldChange,
              newChange,
            );
            changes.push(formattedChange);
          }
        }
      }
    }

    if (changes.length > 0) {
      await createChange(action, changes);

      if (isCSStaff && !isInspector) {
      } else {
        this.clearRequests(orgId, planAppId, changes);
      }
    }
  }; // end saveAsNewChangeRequests

  getRequestIdForQuestion = (questionId, matrixRowId) =>
    R.pipe(
      R.filter(
        R.whereEq({
          question_id: questionId,
          matrix_row_id: matrixRowId,
        }),
      ),
      R.pluck('id'),
    )(this.props.requestList);

  getRequestIdForWorksheet = worksheetId =>
    R.pipe(
      R.filter(
        R.whereEq({
          worksheet_id: worksheetId,
          matrix_row_id: null,
          question_id: null,
        }),
      ),
      R.pluck('id'),
    )(this.props.requestList);

  clearRequests = (orgId, planAppId, updateChangeList) => {
    const { clearRequest, requestList } = this.props;
    updateChangeList.map(change => {
      // clear question requests
      const requestIds = this.getRequestIdForQuestion(
        change.questionId,
        change.matrixRowId,
      );

      requestIds.map(requestId => clearRequest(orgId, planAppId, requestId));
    });
    updateChangeList.map(change => {
      // clear worksheet request
      const requestIds = this.getRequestIdForQuestion(change.questionId);
      const wsRequestId = this.getRequestIdForWorksheet(change.worksheetId);
      if (R.isEmpty(requestIds) && R.not(R.isEmpty(wsRequestId))) {
        clearRequest(orgId, planAppId, wsRequestId);
      }
    });
  };

  acceptChanges = (orgId, planAppId, changeRequestId, updateChangeList) => {
    const { acceptChange } = this.props;
    updateChangeList.map(change => {
      const { action } = acceptChangeResource(
        orgId,
        planAppId,
        changeRequestId,
        change.id,
      );
      acceptChange(action);
    });
  };

  saveChangeRequestChanges = async (values, formikBag, saveChanges) => {
    const {
      orgId,
      planAppId,
      isStateInitialApplication,
      toast,
      isCSStaff,
      changeRequestId,
      changeList,
      createChange,
      acceptChange,
      isInspector,
      userId,
      worksheet,
    } = this.props;

    const { action } = getCreateChangeResource(orgId, planAppId, changeRequestId);
    const updateChangeList = this.buildUpdatedChangeList(values.worksheetAnswersChanges);
    const hasUpdates = updateChangeList && !R.isEmpty(updateChangeList);
    const matrixRows = R.path(['answers', 'matrixRows'])(values.worksheetAnswers);
    const changeValues = R.path(['answers', 'values'])(values.worksheetAnswers);
    const isWorksheetMatrixRowChange = R.not(R.isEmpty(matrixRows));
    const changes = [];

    if (hasUpdates) {
      await saveChanges({ changes: updateChangeList });
      if (isCSStaff && !isInspector) {
      } else {
        this.clearRequests(orgId, planAppId, updateChangeList);
      }
    }

    changeList.map(change => {
      const lastModifyById = R.path(['lastModifiedBy', 'id'], change);
      const userOwnsChange = lastModifyById === userId;

      if (change.state === 'rejected') {
        if (isWorksheetMatrixRowChange) {
          let currentAnswerValue = null;
          let newAnswerValue = null;
          if (
            typeof values.worksheetAnswersChanges.matrixRows[change.matrixRowId] !==
            'undefined'
          ) {
            currentAnswerValue =
              values.worksheetAnswersChanges.matrixRows[change.matrixRowId].values[
                change.questionId
              ];
          }
          if (
            typeof values.worksheetAnswers.answers.matrixRows[change.matrixRowId] !==
            'undefined'
          ) {
            if (
              typeof values.worksheetAnswers.answers.matrixRows[change.matrixRowId]
                .values[change.questionId] !== 'undefined'
            ) {
              newAnswerValue =
                values.worksheetAnswers.answers.matrixRows[change.matrixRowId].values[
                  change.questionId
                ];
            }
          }
          if (
            currentAnswerValue !== newAnswerValue &&
            !deepEqual(change.old, newAnswerValue)
          ) {
            if (change.old !== newAnswerValue) {
              const changeData = this.formatChangeRequest(
                change.questionId,
                change.matrixRowId,
                null,
                change.old,
                newAnswerValue,
              );
              changes.push(changeData);
            }
          }
        }

        // This code doesn't apply to matrix worksheets as it's referencing standard worksheet values
      } else if (!worksheet.matrix) {
        const currentAnswerValue =
          values.worksheetAnswersChanges.values[change.questionId];
        const newAnswerValue = values.worksheetAnswers.answers.values[change.questionId];

        if (
          currentAnswerValue !== newAnswerValue &&
          !deepEqual(change.old, newAnswerValue)
        ) {
          if (change.old !== newAnswerValue) {
            const changeData = this.formatChangeRequest(
              change.questionId,
              null,
              null,
              change.old,
              newAnswerValue,
            );
            changes.push(changeData);
          }
        }
      }
    });

    if (changes.length > 0) {
      createChange(action, changes);
      if (!isCSStaff) {
        this.clearRequests(orgId, planAppId, changes);
      }
    }

    // only save worksheetAnswers for New Applications
    if (!isStateInitialApplication) {
      await toast('success', 'Worksheet changes saved.');
    }
  };

  saveAnswerChanges = async (values, currentAnswers, formikBag, saveWorksheetAnswers) => {
    const { isStateInitialApplication, toast, isCSStaff } = this.props;

    const newAnswers = values.worksheetAnswers;
    const newChanges = values.worksheetAnswersChanges;
    const hasUpdates = !deepEqual(currentAnswers, newAnswers);

    // only save worksheetAnswers for New Applications
    // or setup new change requests for streamlining change requests
    if (hasUpdates) {
      if (isStateInitialApplication) {
        await saveWorksheetAnswers({
          osp_application_answer: {
            osp_application_id: newAnswers.ospAppId,
            worksheet_uuid: newAnswers.worksheetId,
            answers: newAnswers.answers,
            is_not_applicable: newAnswers.isNotApplicable,
            ready_for_review: newAnswers.readyForReview,
          },
        });

        await this.loadPlanApp();
        await toast('success', 'Worksheet saved.');
      } else {
        // save changes as new change requests
        const changeRequestList = this.buildFormChangeList(
          currentAnswers,
          newAnswers,
          newChanges,
        );

        await this.saveAsNewChangeRequests(changeRequestList, currentAnswers, newAnswers);
        await this.loadPlanApp();
      }
    } else {
      await toast('success', 'Worksheet saved.');
    }
  };

  setNoRedirect() {
    this.formik.current.setStatus({ redirectTo: false });
  }

  setRedirectLocationOverview() {
    const { orgId, planAppId } = this.props;
    const redirectTo = locationOverviewRoute(orgId, planAppId);
    this.formik.current.setStatus({ redirectTo });
  }

  setRedirectToWorksheet(worksheetId) {
    const { orgId, planAppId } = this.props;
    const redirectTo = worksheetRoute(orgId, planAppId, worksheetId);
    this.formik.current.setStatus({ redirectTo });
  }

  render() {
    const {
      orgId,
      planAppId,
      worksheetId,
      worksheet,
      showAcaUI,
      changeRequest,
      changeList,
      isCSStaff,
      isPlanAppLocked,
      isPlanAppClosed,
      isStateInitialApplication,
      isStateUnderReview,
      changeRequestIsOpen,
      initialValues,
      isWorksheetNotApplicable,
      worksheetQuestionList,
      requestList,
      isInspector,
    } = this.props;

    if (this.state.isLoading) return <Spinner />;

    return (
      <PlanAppWorksheetAnswersLoader>
        {({ updateResource: saveWorksheetAnswers }) => (
          <PlanAppChangeLoader action="update">
            {({ updateResource: saveChanges }) => (
              <Formik
                ref={this.formik}
                initialValues={initialValues}
                onSubmit={(values, formikBag) => {
                  this.handleSubmit(
                    this.formik.current,
                    values,
                    formikBag,
                    saveChanges,
                    saveWorksheetAnswers,
                  );
                }}
              >
                {({ values, isSubmitting, dirty }) => {
                  return (
                    <PlanAppWorksheetForm
                      orgId={orgId}
                      planAppId={planAppId}
                      worksheetId={worksheetId}
                      worksheet={worksheet}
                      values={values}
                      onClickSubmit={this.onClickSubmit}
                      onClickSubmitNext={this.onClickSubmitNext}
                      onClickSubmitPrev={this.onClickSubmitPrev}
                      showAcaUI={showAcaUI}
                      changeRequest={changeRequest}
                      changeList={changeList}
                      isCSStaff={isCSStaff}
                      isSubmitting={isSubmitting}
                      isPlanAppLocked={isPlanAppLocked}
                      isPlanAppClosed={isPlanAppClosed}
                      isStateInitialApplication={isStateInitialApplication}
                      isStateUnderReview={isStateUnderReview}
                      changeRequestIsOpen={changeRequestIsOpen}
                      isWorksheetNotApplicable={isWorksheetNotApplicable}
                      worksheetQuestionList={worksheetQuestionList}
                      requestList={requestList}
                      isDirty={dirty}
                      isInspector={isInspector}
                    />
                  );
                }}
              </Formik>
            )}
          </PlanAppChangeLoader>
        )}
      </PlanAppWorksheetAnswersLoader>
    );
  }
}

PlanAppWorksheetFormContainer.propTypes = {
  orgId: sowTypes.orgIdType.isRequired,
  planAppId: sowTypes.planAppIdType.isRequired,
  worksheetId: sowTypes.worksheetIdType.isRequired,
  changeRequestId: sowTypes.planAppChangeRequestIdType,
  nextWorksheetId: sowTypes.worksheetIdType,
  prevWorksheetId: sowTypes.worksheetIdType,
  changeList: sowTypes.planAppChangeListType,
  changeRequest: sowTypes.planAppChangeRequestType,
  showAcaUI: PropTypes.bool,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  worksheetAnswers: sowTypes.planAppWorksheetAnswersType,
  redirect: PropTypes.func.isRequired,
  isPlanAppLocked: PropTypes.bool,
  isPlanAppClosed: PropTypes.bool,
  isStateInitialApplication: PropTypes.bool,
  changeRequestIsOpen: PropTypes.bool,
  initialValues: PropTypes.object,
  isWorksheetNotApplicable: PropTypes.bool.isRequired,
  loadPlanApp: PropTypes.func.isRequired,
  worksheetQuestionList: PropTypes.array.isRequired,
  requestList: PropTypes.array,
  clearRequest: PropTypes.func,
  acceptChange: PropTypes.func,
  toast: PropTypes.func.isRequired,
  isInspector: PropTypes.bool,
};

export default R.compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PlanAppWorksheetFormContainer);

import * as R from 'ramda';
import deepEqual from 'fast-deep-equal';

import { resourceUpdate, resourceDetailRead } from 'sow/store/helpers';
import * as adminRequestActions from 'sow/actions/adminRequest';

const getCreateChangeResource = (orgId, planAppId, changeRequestId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes`,
    'changeRequestOverviewResp',
  );

const acceptChangeResource = (orgId, planAppId, changeRequestId, changeId) =>
  resourceUpdate(
    `org/${orgId}/application/${planAppId}/change_request/${changeRequestId}/changes/${changeId}/accept`,
    'changeRequestOverviewResp',
  );

export const formatMissingAnswerStructure = worksheetAnswers => {
  const answers = R.clone(worksheetAnswers);
  if (worksheetAnswers && worksheetAnswers.hasOwnProperty('answers')) {
    if (!worksheetAnswers.answers.hasOwnProperty('values')) {
      answers.answers.values = {};
    }
    if (!worksheetAnswers.answers.hasOwnProperty('matrixRows')) {
      answers.answers.matrixRows = {};
    }
  }
  return answers;
};

export const getRequestIdForQuestion = (questionId, requestList) =>
  R.pipe(
    R.filter(
      R.whereEq({
        question_id: questionId,
      }),
    ),
    R.pluck('id'),
  )(requestList);

export const getRequestIdForWorksheet = (worksheetId, requestList) =>
  R.pipe(
    R.filter(
      R.whereEq({
        worksheet_id: worksheetId,
        matrix_row_id: null,
        question_id: null,
      }),
    ),
    R.pluck('id'),
  )(requestList);

export const clearRequestsForLocationWorksheets = (
  orgId,
  planAppId,
  updateChangeList,
  changeMap,
  clearRequest,
  requestList,
) => {
  updateChangeList.map(change => {
    // clear question requests
    const requestIds = getRequestIdForQuestion(change.questionId, requestList);
    requestIds.map(requestId => clearRequest(orgId, planAppId, requestId));
  });
  updateChangeList.map(change => {
    // clear worksheet request
    const requestIds = getRequestIdForQuestion(change.questionId, requestList);
    const wsRequestId = getRequestIdForWorksheet(change.worksheetId, requestList);
    if (R.isEmpty(requestIds) && R.not(R.isEmpty(wsRequestId))) {
      clearRequest(orgId, planAppId, wsRequestId);
    }
  });
};

export const clearLocationRequests = async (
  orgId,
  planAppId,
  changedValues,
  initialValues,
  requestList,
  clearRequest,
  locationId,
) => {
  let requestIds = [];

  const changedAnswers = R.path(['answers', 'worksheets'], changedValues);
  const initialAnswers = R.path(['answers', 'worksheets'], initialValues);
  const changedAnswersChanges = R.path(['answersChanges', 'worksheets'], changedValues);
  const initialAnswersChanges = R.path(['answersChanges', 'worksheets'], initialValues);

  if (requestList.length === 0) {
    return;
  } else {
    requestIds = requestList.map(request => {
      if (request.location_id == locationId) {
        const worksheetPath = [
          request.worksheet_id,
          'answers',
          'values',
          request.question_id,
        ];
        const matrixPath = [
          request.worksheet_id,
          'answers',
          'matrixRows',
          request.matrix_row_id,
          'values',
          request.question_id,
        ];
        const changedWorksheetAnswers = R.path(worksheetPath, changedAnswers);
        const initialWorksheetAnswers = R.path(worksheetPath, initialAnswers);
        const changedWorksheetAnswersChanges = R.path(
          worksheetPath,
          changedAnswersChanges,
        );
        const initialWorksheetAnswersChanges = R.path(
          worksheetPath,
          initialAnswersChanges,
        );
        const changedWorksheetMatrixAnswers = R.path(matrixPath, changedAnswers);
        const initialWorksheetMatrixAnswers = R.path(matrixPath, initialAnswers);
        const changedWorksheetMatrixAnswersChanges = R.path(
          matrixPath,
          changedAnswersChanges,
        );

        const initialWorksheetMatrixAnswersChanges = R.path(
          matrixPath,
          initialAnswersChanges,
        );
        if (
          JSON.stringify(changedWorksheetAnswers) !==
          JSON.stringify(initialWorksheetAnswers)
        ) {
          return request.id;
        }
        if (
          JSON.stringify(changedWorksheetAnswersChanges) !==
          JSON.stringify(initialWorksheetAnswersChanges)
        ) {
          return request.id;
        }
        if (
          JSON.stringify(changedWorksheetMatrixAnswers) !==
          JSON.stringify(initialWorksheetMatrixAnswers)
        ) {
          return request.id;
        }
        if (
          JSON.stringify(changedWorksheetMatrixAnswersChanges) !==
          JSON.stringify(initialWorksheetMatrixAnswersChanges)
        ) {
          return request.id;
        }
      }
      return null;
    });
  }

  const requestIdsArray = requestIds.filter(id => id !== null);

  requestIdsArray.forEach(id => clearRequest(orgId, planAppId, id));

  return;
};

export const clearRequestsForWorksheet = (
  orgId,
  planAppId,
  worksheetId,
  clearRequest,
  requestList,
) => {
  requestList.map(request => {
    if (request.worksheet_id === worksheetId) {
      clearRequest(orgId, planAppId, request.id);
    }
  });
};

export const clearRequestsForLocationWorksheet = (
  orgId,
  planAppId,
  worksheetId,
  locationId,
  clearRequest,
  requestList,
) => {
  R.map(request => {
    if (
      R.propEq('worksheet_id', worksheetId)(request) &&
      R.propEq('location_id', locationId)(request)
    ) {
      clearRequest(orgId, planAppId, request.id);
    }
  })(requestList);
};

export const formatChangeRequest = (
  questionId,
  matrixRowId,
  locationId,
  oldValue,
  newValue,
  planAppId,
  changeRequestId,
  worksheetId,
) => {
  return {
    context: R.isNil(locationId) ? 'main' : 'land',
    type: R.isNil(matrixRowId) ? 'worksheet_answer' : 'matrix_row_answer',
    action: R.isNil(oldValue) ? 'added' : 'updated',
    applicationId: planAppId,
    changeRequestId: changeRequestId,
    worksheetId: worksheetId,
    questionId: questionId,
    matrixRowId: matrixRowId,
    landId: locationId,
    old: oldValue,
    new: newValue,
  };
};

// currentChangeRequest is a map with current change values
export const buildFormChangeList = (
  currentAnswers,
  newAnswers,
  currentChangeRequests,
  changes,
  changeList,
) => {
  const hasUpdates = !deepEqual(currentAnswers, newAnswers);
  const valuesForMatrixRow = row => R.pathOr(null, ['values'], row);
  const hasValuesForMatrixRow = row => R.not(R.isNil(valuesForMatrixRow(row)));
  const answerForMatrixRow = (row, questionId) =>
    R.pathOr(null, ['values', questionId], row);
  const hasAnswerForMatrixRow = (row, questionId) =>
    R.not(R.isNil(answerForMatrixRow(row, questionId)));
  const hasCurrentChange = questionId => R.pathEq(['questionId'], questionId);
  const getChangeNewValue = changes => R.pluck('new', changes);
  const getCurrentChange = questionId =>
    getChangeNewValue(R.filter(hasCurrentChange(questionId))(changeList));

  // function is available to buildFormChangeList only
  const removeQuestionsWithOpenChangeRequests = (
    currentAnswers,
    currentChangeRequests,
  ) => {
    const baseAnswers = R.clone(currentAnswers);
    const matrixRows = R.path(['matrixRows'])(currentChangeRequests);
    const changeValues = R.path(['values'])(currentChangeRequests);
    const hasMatrixRowChanges = R.not(R.isEmpty(matrixRows));
    const hasChangeValues = R.not(R.isEmpty(changeValues));

    if (R.not(R.isEmpty(baseAnswers))) {
      // remove row changes with existing change requests
      if (hasMatrixRowChanges) {
        for (var rowId in matrixRows) {
          const matrixValues = matrixRows[rowId]['values'];
          for (var questionId in matrixValues) {
            if (
              hasAnswerForMatrixRow(
                baseAnswers['answers']['matrixRows'][rowId],
                questionId,
              ) &&
              baseAnswers['answers']['matrixRows'][rowId]
            ) {
              delete baseAnswers['answers']['matrixRows'][rowId]['values'][questionId];
              if (R.isEmpty(baseAnswers['answers']['matrixRows'][rowId]['values'])) {
                delete baseAnswers['answers']['matrixRows'][rowId];
              }
            }
          }
        }
      }

      // remove changes with existing change requests
      if (hasChangeValues && R.not(R.isEmpty(baseAnswers))) {
        if (R.not(R.isEmpty(baseAnswers.answers.values))) {
          for (var questionId in baseAnswers.answers.values) {
            const compareChange = getCurrentChange(questionId)[0];
            if (compareChange === baseAnswers['answers']['values'][questionId]) {
              delete baseAnswers['answers']['values'][questionId];
            }
          }
        }

        for (var questionId in changeValues) {
          if (baseAnswers['answers']['values'].hasOwnProperty(questionId)) {
            delete baseAnswers['answers']['values'][questionId];
          }
        }
      }
    }

    return baseAnswers;
  };

  const removeQuestionsInChangeList = (answers, changeList) => {
    const baseAnswers = R.clone(answers);
    const matrixRows = R.path(['answers', 'matrixRows'])(baseAnswers);
    const changeValues = R.path(['answers', 'values'])(baseAnswers);
    const hasMatrixRowChanges = R.not(R.isEmpty(matrixRows));
    const hasChangeValues = R.not(R.isEmpty(changeValues));
    const getChangeRequests = questionId =>
      R.filter(R.whereEq({ questionId: questionId }))(changeList);

    if (hasChangeValues) {
      for (var questionId in changeValues) {
        const crs = getChangeRequests(questionId);
        if (R.not(R.isEmpty(crs))) {
          delete baseAnswers['answers']['values'][questionId];
        }
      }
    }

    if (hasMatrixRowChanges) {
      for (var rowId in matrixRows) {
        const matrixValues = matrixRows[rowId]['values'];
        for (var questionId in matrixValues) {
          const crs = getChangeRequests(questionId);
          if (R.not(R.isEmpty(crs))) {
            delete baseAnswers['answers']['matrixRows'][rowId][questionId];
          }
        }
      }
    }

    return baseAnswers;
  };

  // mergeUpdatedAnswers function is available to buildFormChangeList only
  const mergeUpdatedAnswers = function(currentAnswers, newAnswers) {
    const mergedValues = R.clone(currentAnswers);
    const matrixRows = R.path(['answers', 'matrixRows'])(newAnswers);
    const changeValues = R.path(['answers', 'values'])(newAnswers);
    const isWorksheetMatrixRowChange = R.not(R.isEmpty(matrixRows));
    const hasChangeValues = R.not(R.isEmpty(changeValues));

    if (isWorksheetMatrixRowChange) {
      for (var rowId in matrixRows) {
        if (hasValuesForMatrixRow(matrixRows[rowId])) {
          const matrixValues = matrixRows[rowId]['values'];
          for (var answer in matrixValues) {
            if (
              R.isEmpty(mergedValues['answers']['matrixRows']) ||
              R.isNil(mergedValues['answers']['matrixRows'][rowId])
            ) {
              mergedValues['answers']['matrixRows'][rowId] = {};
              mergedValues['answers']['matrixRows'][rowId]['values'] = {};
            }
            mergedValues['answers']['matrixRows'][rowId]['values'][answer] =
              matrixValues[answer];
          }
        }
      }
    } else if (hasChangeValues) {
      for (var answer in changeValues) {
        mergedValues['answers']['values'][answer] = changeValues[answer];
      }
    }

    return mergedValues;
  };

  // deepDiffMapper function is available to buildFormChangeList only
  const deepDiffMapper = (function() {
    return {
      VALUE_CREATED: 'created',
      VALUE_UPDATED: 'updated',
      VALUE_DELETED: 'deleted',
      VALUE_UNCHANGED: 'unchanged',
      map: function(obj1, obj2) {
        if (this.isFunction(obj1) || this.isFunction(obj2)) {
          throw 'Invalid argument. Function given, object expected.';
        }

        if (this.isValue(obj1) || this.isValue(obj2)) {
          let type = this.compareValues(obj1, obj2);
          return {
            type: type,
            data: this.getChangedValue(obj1, obj2, type),
            old: obj1,
          };
        }

        if (
          this.isArray(obj1) &&
          this.isArray(obj2) &&
          typeof obj1[0] === 'object' &&
          typeof obj2[0] === 'object'
        ) {
          let type = this.compareValues(obj1, obj2);
          return { type: type, data: this.getChangedValue(obj1, obj2, type), old: obj1 };
        } else if (this.isArray(obj1) && this.isArray(obj2)) {
          let type = this.compareValues(obj1, obj2);
          return { type: type, data: this.getChangedValue(obj1, obj2, type), old: obj1 };
        }

        var diff = {};

        for (var key in obj1) {
          if (this.isFunction(obj1[key])) {
            continue;
          }

          var value2 = undefined;
          if (obj2[key] !== undefined) {
            value2 = obj2[key];
          }

          diff[key] = this.map(obj1[key], value2);
        }

        for (var key in obj2) {
          if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
            continue;
          }

          diff[key] = this.map(undefined, obj2[key]);
        }

        return diff;
      },
      compareValues: function(value1, value2) {
        if (this.isArray(value1) && this.isArray(value2)) {
          if (JSON.stringify(value1) === JSON.stringify(value2)) {
            return this.VALUE_UNCHANGED;
          } else {
            return this.VALUE_UPDATED;
          }
        } else {
          if (value1 === value2) {
            return this.VALUE_UNCHANGED;
          }
          if (
            this.isDate(value1) &&
            this.isDate(value2) &&
            value1.getTime() === value2.getTime()
          ) {
            return this.VALUE_UNCHANGED;
          }
          if (value1 === undefined) {
            return this.VALUE_CREATED;
          }
          if (value2 === undefined) {
            return this.VALUE_DELETED;
          }
          return this.VALUE_UPDATED;
        }
      },
      getChangedValue: function(value1, value2, type) {
        let newVal = value1;
        if (type === this.VALUE_UPDATED) {
          newVal = value2;
        }
        if (type === this.VALUE_CREATED) {
          newVal = value2;
        }
        return newVal;
      },
      isFunction: function(x) {
        return Object.prototype.toString.call(x) === '[object Function]';
      },
      isArray: function(x) {
        return Object.prototype.toString.call(x) === '[object Array]';
      },
      isDate: function(x) {
        return Object.prototype.toString.call(x) === '[object Date]';
      },
      isObject: function(x) {
        return Object.prototype.toString.call(x) === '[object Object]';
      },
      isValue: function(x) {
        return !this.isObject(x) && !this.isArray(x);
      },
    };
  })();

  if (hasUpdates) {
    // reconcile original and new values
    if (R.not(R.isNil(changeList) && R.isEmpty(changeList))) {
      const baseAnswers = removeQuestionsInChangeList(currentAnswers, changeList);
      const updatedAnswers = removeQuestionsInChangeList(newAnswers, changeList);
      return deepDiffMapper.map(baseAnswers, updatedAnswers);
    } else {
      const baseAnswers = removeQuestionsWithOpenChangeRequests(
        currentAnswers,
        currentChangeRequests,
      );
      const updatedAnswers = removeQuestionsWithOpenChangeRequests(
        newAnswers,
        currentChangeRequests,
      );
      return deepDiffMapper.map(baseAnswers, updatedAnswers);
    }
  } else {
    return {};
  }
};

export const getWorksheetChanges = (
  changeList,
  orgId,
  planAppId,
  locationId,
  changeRequestId,
  worksheetId,
  createChange,
  acceptChange,
  isCSStaff,
) => {
  let matrixRows = R.path(['answers', 'matrixRows'])(changeList);
  let changeValues = R.path(['answers', 'values'])(changeList);

  const isWorksheetMatrixRowChange = R.not(R.isEmpty(matrixRows));
  const changes = [];

  const getMatrixAnswerValue = (answers, rowId, questionId) =>
    R.pathOr(null, ['answers', 'matrixRows', rowId, 'values', questionId], answers);

  const getAnswerValue = (answers, questionId) =>
    R.pathOr(null, ['answers', 'values', questionId], answers);

  const hasUpdatedVersionChange = change =>
    typeof change !== 'undefined' &&
    change.hasOwnProperty('__version') &&
    change.__version.type === 'updated';

  const hasChange = change =>
    (typeof change !== 'undefined' &&
      change.hasOwnProperty('type') &&
      change.type !== 'unchanged') ||
    hasUpdatedVersionChange(change);

  const isDeleted = change =>
    typeof change !== 'undefined' && hasChange(change) && change.type === 'deleted';

  const hasNestedChange = change => {
    if (change.hasOwnProperty('0')) {
      // not an array, but zero key
      for (const [key, value] of Object.entries(change)) {
        if (hasChange(value) || hasUpdatedVersionChange(value)) {
          return true;
        }
      }
    }
    if (
      change.hasOwnProperty('__fieldType') &&
      change.__fieldType.data == 'checkbox_list'
    ) {
      for (const [key, value] of Object.entries(change.checked)) {
        if (hasChange(value) || hasUpdatedVersionChange(value)) {
          return true;
        }
      }
      for (const [key, value] of Object.entries(change.unchecked)) {
        if (hasChange(value) || hasUpdatedVersionChange(value)) {
          return true;
        }
      }
    }
    return false;
  };

  if (isWorksheetMatrixRowChange) {
    const matrixRowValues = matrixRows.hasOwnProperty('data')
      ? matrixRows.data
      : matrixRows;

    for (var rowId in matrixRowValues) {
      // check for new row data
      if (matrixRowValues[rowId]) {
        // get newly created data.values
        if (
          matrixRowValues[rowId].hasOwnProperty('data') &&
          matrixRowValues[rowId]['type'] === 'created'
        ) {
          const newMatrixValues = matrixRowValues[rowId]['data']['values'];
          for (var questionId in newMatrixValues) {
            const newChange = newMatrixValues[questionId];
            if (newChange !== null) {
              const formattedChange = formatChangeRequest(
                questionId,
                rowId,
                locationId,
                null,
                newChange,
                planAppId,
                changeRequestId,
                worksheetId,
              );
              changes.push(formattedChange);
            }
          }
        } else if (
          matrixRows.hasOwnProperty('data') &&
          matrixRows['type'] === 'created'
        ) {
          const newMatrixValues = matrixRowValues[rowId]['values'];
          for (var questionId in newMatrixValues) {
            const newChange = matrixRowValues[questionId];
            if (newChange !== null) {
              const formattedChange = formatChangeRequest(
                questionId,
                rowId,
                locationId,
                null,
                newChange,
                planAppId,
                changeRequestId,
                worksheetId,
              );
              changes.push(formattedChange);
            }
          }
        }

        // has updated values
        const matrixValues = matrixRowValues[rowId]['values'];
        for (var questionId in matrixValues) {
          const change = matrixValues[questionId];

          if (change) {
            if (hasChange(change)) {
              const oldChange = getMatrixAnswerValue(oldAnswers, rowId, questionId);
              const newChange = getMatrixAnswerValue(newAnswers, rowId, questionId);
              if (isDeleted(change)) {
                // Do not create an updated change for null > empty or empty > null
                if (change.data.old !== '' && oldChange !== '') {
                  const formattedChange = formatChangeRequest(
                    questionId,
                    rowId,
                    locationId,
                    oldChange,
                    null,
                    planAppId,
                    changeRequestId,
                    worksheetId,
                  );
                  changes.push(formattedChange);
                }
                changes.push(formattedChange);
              } else if (oldChange !== newChange) {
                const formattedChange = formatChangeRequest(
                  questionId,
                  rowId,
                  locationId,
                  oldChange,
                  newChange,
                  planAppId,
                  changeRequestId,
                  worksheetId,
                );
                changes.push(formattedChange);
              }
            }

            if (hasNestedChange(change)) {
              const nestedChange = change[0];
              if (isDeleted(nestedChange) && nestedChange.hasOwnProperty('data')) {
                const oldChange = nestedChange.data;
                const formattedChange = formatChangeRequest(
                  questionId,
                  rowId,
                  locationId,
                  oldChange,
                  null,
                  planAppId,
                  changeRequestId,
                  worksheetId,
                );
                changes.push(formattedChange);
              }
            }
          }
        }
      }
    }
  } else {
    for (var questionId in changeValues) {
      const change = changeValues[questionId];

      if (hasChange(change)) {
        const oldChange = getAnswerValue(oldAnswers, questionId);
        const newChange = getAnswerValue(newAnswers, questionId);
        if (oldChange !== newChange) {
          const formattedChange = formatChangeRequest(
            questionId,
            null,
            locationId,
            oldChange,
            newChange,
            planAppId,
            changeRequestId,
            worksheetId,
          );
          changes.push(formattedChange);
        }
      }

      if (hasNestedChange(change)) {
        const oldChange = getAnswerValue(oldAnswers, questionId);
        const newChange = getAnswerValue(newAnswers, questionId);
        if (oldChange !== newChange) {
          const formattedChange = formatChangeRequest(
            questionId,
            null,
            locationId,
            oldChange,
            newChange,
            planAppId,
            changeRequestId,
            worksheetId,
          );
          changes.push(formattedChange);
        }
      }
    }
  }

  return changes;
};

export const saveAnswerChanges = async (
  values,
  currentAnswers,
  saveWorksheetAnswers,
  isStateInitialApplication,
) => {
  const newAnswers = values.worksheetAnswers;
  const newChanges = values.worksheetAnswersChanges;
  const hasUpdates = !deepEqual(currentAnswers, newAnswers);

  // only save worksheetAnswers for New Applications
  // or setup new change requests for streamlining change requests
  if (hasUpdates) {
    if (isStateInitialApplication) {
      await saveWorksheetAnswers({
        osp_application_answer: {
          osp_application_id: newAnswers.ospAppId,
          worksheet_uuid: newAnswers.worksheetId,
          answers: newAnswers.answers,
          is_not_applicable: newAnswers.isNotApplicable,
          ready_for_review: newAnswers.readyForReview,
        },
      });
    } else {
      // save changes as new change requests
      const changeRequestList = this.buildFormChangeList(
        currentAnswers,
        newAnswers,
        newChanges,
      );

      await this.saveAsNewChangeRequests(changeRequestList, currentAnswers, newAnswers);
    }
  }
};

export const saveAsNewChangeRequests = async (
  changeList,
  oldAnswers,
  newAnswers,
  orgId,
  planAppId,
  locationId,
  worksheetId,
  changeRequestId,
  createChange,
  getAcceptChangeResource,
  dispatch,
  clearRequest,
  requestList,
  isCSStaff,
  locationAnswersChangesMap,
  userId,
) => {
  const { action } = getCreateChangeResource(orgId, planAppId, changeRequestId);

  const dataMatrixRows = R.path(['data', 'answers', 'matrixRows'])(changeList);
  const isDataMatrixRowChange =
    R.not(R.isNil(dataMatrixRows)) && R.not(R.isEmpty(dataMatrixRows));

  let matrixRows = isDataMatrixRowChange
    ? dataMatrixRows
    : R.path(['answers', 'matrixRows'])(changeList);
  let changeValues = R.path(['answers', 'values'])(changeList);

  const isWorksheetMatrixRowChange =
    R.not(R.isNil(matrixRows)) && R.not(R.isEmpty(matrixRows));
  const changes = [];
  const getMatrixAnswerValue = (answers, rowId, questionId) =>
    R.pathOr(null, ['answers', 'matrixRows', rowId, 'values', questionId], answers);
  const getAnswerValue = (answers, questionId) =>
    R.pathOr(null, ['answers', 'values', questionId], answers);
  const hasUpdatedVersionChange = change =>
    typeof change !== 'undefined' &&
    change.hasOwnProperty('__version') &&
    change.__version.type === 'updated';
  const hasChange = change =>
    (typeof change !== 'undefined' &&
      change.hasOwnProperty('type') &&
      change.type !== 'unchanged') ||
    hasUpdatedVersionChange(change);
  const isDeleted = change =>
    typeof change !== 'undefined' && hasChange(change) && change.type === 'deleted';
  const hasNestedChange = change => {
    if (change.hasOwnProperty('0')) {
      // not an array, but zero key
      for (const [key, value] of Object.entries(change)) {
        if (hasChange(value) || hasUpdatedVersionChange(value)) {
          return true;
        }
      }
    }
    if (
      change.hasOwnProperty('__fieldType') &&
      change.__fieldType.data == 'checkbox_list'
    ) {
      for (const [key, value] of Object.entries(change.checked)) {
        if (hasChange({ [key]: value }) || hasUpdatedVersionChange(value)) {
          return true;
        }
      }
      for (const [key, value] of Object.entries(change.unchecked)) {
        if (hasChange({ [key]: value }) || hasUpdatedVersionChange(value)) {
          return true;
        }
      }
    }
    return false;
  };

  if (isWorksheetMatrixRowChange) {
    const matrixRowValues = matrixRows.hasOwnProperty('data')
      ? matrixRows.data
      : matrixRows;

    for (var rowId in matrixRowValues) {
      // check for new row data
      if (matrixRowValues[rowId]) {
        // get newly created data.values
        if (
          matrixRowValues[rowId].hasOwnProperty('data') &&
          matrixRowValues[rowId]['type'] === 'created'
        ) {
          const newMatrixValues = matrixRowValues[rowId]['data']['values'];
          for (var questionId in newMatrixValues) {
            const newChange = newMatrixValues[questionId];
            if (newChange !== null) {
              const formattedChange = formatChangeRequest(
                questionId,
                rowId,
                locationId,
                null,
                newChange,
                planAppId,
                changeRequestId,
                worksheetId,
              );
              changes.push(formattedChange);
            }
          }
        } else if (
          matrixRows.hasOwnProperty('data') &&
          matrixRows['type'] === 'created'
        ) {
          const newMatrixValues = matrixRowValues[rowId]['values'];
          for (var questionId in newMatrixValues) {
            const newChange = matrixRowValues[questionId];
            if (newChange !== null) {
              const formattedChange = formatChangeRequest(
                questionId,
                rowId,
                locationId,
                null,
                newChange,
                planAppId,
                changeRequestId,
                worksheetId,
              );
              changes.push(formattedChange);
            }
          }
        } else if (isDataMatrixRowChange) {
          const matrixValues = matrixRowValues[rowId]['values'];
          for (var questionId in matrixValues) {
            const newChange = matrixValues[questionId];
            const formattedChange = formatChangeRequest(
              questionId,
              rowId,
              locationId,
              null,
              newChange,
              planAppId,
              changeRequestId,
              worksheetId,
            );
            changes.push(formattedChange);
          }
        }

        // has updated values
        const matrixValues = matrixRowValues[rowId]['values'];
        for (var questionId in matrixValues) {
          const change = matrixValues[questionId];
          if (change) {
            if (hasChange(change)) {
              const oldChange = getMatrixAnswerValue(oldAnswers, rowId, questionId);
              const newChange = getMatrixAnswerValue(newAnswers, rowId, questionId);
              if (isDeleted(change)) {
                const formattedChange = formatChangeRequest(
                  questionId,
                  rowId,
                  locationId,
                  oldChange,
                  null,
                  planAppId,
                  changeRequestId,
                  worksheetId,
                );
                changes.push(formattedChange);
              } else if (oldChange !== newChange) {
                const formattedChange = formatChangeRequest(
                  questionId,
                  rowId,
                  locationId,
                  oldChange,
                  newChange,
                  planAppId,
                  changeRequestId,
                  worksheetId,
                );
                changes.push(formattedChange);
              }
            }

            if (hasNestedChange(change)) {
              const nestedChange = change[0];
              if (isDeleted(nestedChange) && nestedChange.hasOwnProperty('data')) {
                const oldChange = nestedChange.data;
                const formattedChange = formatChangeRequest(
                  questionId,
                  rowId,
                  locationId,
                  oldChange,
                  null,
                  planAppId,
                  changeRequestId,
                  worksheetId,
                );
                changes.push(formattedChange);
              }
            }
          }
        }
      }
    }
  } else {
    // get newly created data.values
    if (changeList.hasOwnProperty('data') && changeList['type'] === 'created') {
      const newChangeValues = changeList['data']['answers']['values'];
      for (var questionId in newChangeValues) {
        const newChange = newChangeValues[questionId];
        if (newChange !== null) {
          const formattedChange = formatChangeRequest(
            questionId,
            rowId,
            locationId,
            null,
            newChange,
            planAppId,
            changeRequestId,
            worksheetId,
          );
          changes.push(formattedChange);
        }
      }
    }

    for (var questionId in changeValues) {
      const change = changeValues[questionId];

      if (hasChange(change)) {
        const oldChange = getAnswerValue(oldAnswers, questionId);
        const newChange = getAnswerValue(newAnswers, questionId);
        if (oldChange !== newChange) {
          const formattedChange = formatChangeRequest(
            questionId,
            null,
            locationId,
            oldChange,
            newChange,
            planAppId,
            changeRequestId,
            worksheetId,
          );
          changes.push(formattedChange);
        }
      }

      if (hasNestedChange(change)) {
        const oldChange = getAnswerValue(oldAnswers, questionId);
        const newChange = getAnswerValue(newAnswers, questionId);
        if (oldChange !== newChange) {
          const formattedChange = formatChangeRequest(
            questionId,
            null,
            locationId,
            oldChange,
            newChange,
            planAppId,
            changeRequestId,
            worksheetId,
          );
          changes.push(formattedChange);
        }
      }
    }
  }

  if (changes.length > 0) {
    await createChange(changes);
  }
}; // end saveAsNewChangeRequests

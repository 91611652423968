import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import * as sowTypes from 'sow/types';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import Block from 'sow/components/atoms/Block';
import Button from 'sow/components/atoms/Button';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import LocationWorksheetQuestion from 'sow/containers/planApp/LocationWorksheetQuestion';
import LocationWorksheetMatrix from 'sow/containers/planApp/LocationWorksheetMatrix';

const PlanAppLocationWorksheet = ({
  worksheetId,
  worksheet,
  questions,
  values,
  locationAnswersChangesMap,
  clearWorksheetRequests,
  isPlanAppClosed,
  isStateUnderReview,
  isStaff,
}) => {
  const isMatrix = R.prop('matrix', worksheet);

  return (
    <Widget>
      <WidgetHeading>
        {worksheet.name}
        {isStaff && !isPlanAppClosed && isStateUnderReview && (
          <Block pullRight>
            <Button className="no-margin-button" onClick={clearWorksheetRequests}>
              Clear To-dos
            </Button>
          </Block>
        )}
      </WidgetHeading>
      <WidgetBody>
        {worksheet.header && <InfoPanel text={worksheet.header} />}
        {isMatrix && (
          <LocationWorksheetMatrix
            worksheetId={worksheetId}
            values={values}
            locationAnswersChangesMap={locationAnswersChangesMap}
          />
        )}
        {!isMatrix &&
          questions.map(questionId => (
            <LocationWorksheetQuestion
              key={questionId}
              worksheetId={worksheetId}
              questionId={questionId}
              locationAnswersChangesMap={locationAnswersChangesMap}
            />
          ))}
      </WidgetBody>
    </Widget>
  );
};

PlanAppLocationWorksheet.propTypes = {
  worksheetId: sowTypes.planAppWorksheetIdType.isRequired,
  worksheet: sowTypes.planAppWorksheetType.isRequired,
  values: PropTypes.object.isRequired,
  questions: PropTypes.arrayOf(sowTypes.worksheetQuestionIdType).isRequired,
};

export default PlanAppLocationWorksheet;

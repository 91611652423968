import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import Tooltip from 'sow/components/molecules/Tooltip';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Button from 'sow/components/atoms/Button';
import FontAwesome from 'sow/components/atoms/FontAwesome';
import WorksheetMatrixDeleteRowButton from 'sow/components/organisms/WorksheetMatrixDeleteRowButton';
import WorksheetMatrixAnswerModal from 'sow/components/organisms/WorksheetMatrixAnswerModal';
import WorksheetMatrixCell from 'sow/containers/planApp/WorksheetMatrixCell';
import NewRowIndicator from 'sow/containers/planApp/MatrixRowNewRowIndicator';
import MatrixRowDeletionChange from 'sow/containers/planApp/MatrixRowDeletionChange';
import ModalOpenState from 'sow/containers/ModalOpenState';
import ScrollTarget from 'sow/components/atoms/ScrollTarget';

const WorksheetMatrixRow = ({
  questions,
  matrixRowId,
  orgId,
  planAppId,
  worksheetId,
  locationId,
  changeRequest,
  changeList,
  showAcaUI,
  controlsDisabled,
  isWorksheetLocked,
  changeUIDisabled,
  isRowDeleted,
  baseAnswersName,
  baseAnswersChangesName,
  acceptAll,
  discardAll,
  isCSStaff,
  isInspector,
}) => (
  <TableRow>
    {/* This doesn't seem to work correctly so hiding for now
    <TableCell valignMiddle>
      <NewRowIndicator matrixRowId={matrixRowId} />
    </TableCell>
    */}
    {questions.map(questionId => (
      <WorksheetMatrixCell
        key={`${matrixRowId}-${questionId}`}
        questionId={questionId}
        matrixRowId={matrixRowId}
        locationId={locationId}
        isRowDeleted={isRowDeleted}
        baseAnswersName={baseAnswersName}
        baseAnswersChangesName={baseAnswersChangesName}
      />
    ))}
    <TableCell textRight className="col-xs-1">
      <ScrollTarget.PlanAppWorksheetMatrixRow matrixRowId={matrixRowId} />
      <ModalOpenState id={matrixRowId}>
        {({ show, onHide }) => (
          <WorksheetMatrixAnswerModal
            title="Add Answer"
            matrixRowId={matrixRowId}
            orgId={orgId}
            planAppId={planAppId}
            worksheetId={worksheetId}
            locationId={locationId}
            changeRequest={changeRequest}
            changeList={changeList}
            showAcaUI={showAcaUI}
            questions={questions}
            disabled={isWorksheetLocked}
            changeUIDisabled={changeUIDisabled}
            showModal={show}
            onHideModal={onHide}
            baseAnswersName={baseAnswersName}
            baseAnswersChangesName={baseAnswersChangesName}
            isInspector={isInspector}
          >
            {onClick => (
              <Button sm primary onClick={onClick} disabled={isRowDeleted}>
                {controlsDisabled ? (
                  <Tooltip overlay="View row details">
                    <FontAwesome variant="far" icon="eye" />
                  </Tooltip>
                ) : (
                  <FontAwesome icon="pencil-alt" />
                )}
              </Button>
            )}
          </WorksheetMatrixAnswerModal>
        )}
      </ModalOpenState>

      {!controlsDisabled && isCSStaff && !isInspector && changeRequest && (
        <div>
          <Tooltip overlay="Accept All">
            <Button sm style={{ width: '35px' }} onClick={acceptAll}>
              <FontAwesome icon="check" />
            </Button>
          </Tooltip>
          <Tooltip overlay="Discard All">
            <Button sm style={{ width: '35px' }} onClick={discardAll}>
              <FontAwesome icon="ban" />
            </Button>
          </Tooltip>
        </div>
      )}

      {!controlsDisabled && !changeRequest && (
        // On initial application, show button to directly delete matrix row
        <Field
          name={`${baseAnswersName}.matrixRows`}
          component={WorksheetMatrixDeleteRowButton}
          answerUuid={matrixRowId}
          disabled={controlsDisabled}
        />
      )}

      {!controlsDisabled && !!changeRequest && (
        // If a CR exists, show the delete-via-change button instead of regular one
        <MatrixRowDeletionChange
          orgId={orgId}
          planAppId={planAppId}
          changeRequestId={changeRequest.id}
          locationId={locationId}
          worksheetId={worksheetId}
          matrixRowId={matrixRowId}
          isCSStaff={isCSStaff}
          changeUIDisabled={changeUIDisabled}
        />
      )}
    </TableCell>
  </TableRow>
);

WorksheetMatrixRow.propTypes = {
  isRowDeleted: PropTypes.bool.isRequired,
  changeUIDisabled: PropTypes.bool.isRequired,
  baseAnswersName: PropTypes.string.isRequired,
  baseAnswersChangesName: PropTypes.string.isRequired,
};

export default WorksheetMatrixRow;

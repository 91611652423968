import React, { Fragment } from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { geogUSCenter } from 'sow/constants/googleMapCoords';
import Block from 'sow/components/atoms/Block';
import Panel from 'sow/components/molecules/Panel';
import InfoPanel from 'sow/components/organisms/InfoPanel';
import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import Glyphicon from 'sow/components/molecules/Glyphicon';
import FormikField from 'sow/components/organisms/FormikField';
import LandTypeSelect from 'sow/containers/LandTypeSelect';
import LandScopeSelect from 'sow/containers/LandScopeSelect';
import CountrySelect from 'sow/containers/CountrySelect';
import LatLngPickerForm from 'sow/components/organisms/LatLngPickerForm';
// import WorksheetBaseQuestion from 'sow/components/organisms/WorksheetBaseQuestion';
import LocationDetails from 'sow/containers/planApp/LocationDetails';

const PlanAppLocationFormFields = ({ planAppLocation, namePrefix, disabled }) => {
  const fetchLocationCoordinates = encodedLocationAddress => {
    try {
      const { responseJSON } = $.ajax({
        url: `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDM4HLo_7dvhFYya0tsprpmj2n5mXYMdas&sensor=false&address=${encodedLocationAddress}`,
        async: false,
        success: function(result) {
          return result;
        },
      });

      if (responseJSON.status == 'OK') {
        return {
          lat: responseJSON.results[0].geometry.location.lat,
          lng: responseJSON.results[0].geometry.location.lng,
        };
      } else {
        return geogUSCenter;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onLocationChange = (target, values, setFieldValue) => {
    const targetNameArray = target.name.split('.');
    const changedFieldName = targetNameArray.pop();
    let locationAddressObj = values;

    targetNameArray.forEach(key => {
      locationAddressObj = locationAddressObj[key];
    });

    const changedField = { [changedFieldName]: target.value };
    locationAddressObj = R.merge(locationAddressObj, changedField);

    const encodedLocationAddress = encodeURIComponent(
      `${locationAddressObj.address}, ${locationAddressObj.city}, ${locationAddressObj.state}, ${locationAddressObj.postal}, ${locationAddressObj.country}`,
    );

    const { lat, lng } = fetchLocationCoordinates(encodedLocationAddress);

    setFieldValue(`${namePrefix}geom.lat`, lat, true);
    setFieldValue(`${namePrefix}geom.lng`, lng, true);
  };

  return (
    <Block>
      <Panel heading="Details">
        <Field
          name={`${namePrefix}name`}
          label="Location name"
          component={FormikField}
          disabled={disabled}
          required
        />

        <Field
          name={`${namePrefix}locationTypeId`}
          label="Location type"
          component={FormikField}
          type={LandTypeSelect}
          disabled={disabled}
          required
        />
      </Panel>

      <InfoPanel text="Enter an address and/or approximate coordinates of the location:" />

      <Row>
        <Column md={6}>
          <Panel heading="Address">
            {planAppLocation.id && (
              <LocationDetails locationId={planAppLocation.id}>
                {details =>
                  RA.isNilOrEmpty(R.path(['location', 'address'], details)) &&
                  !RA.isNilOrEmpty(R.path(['location', 'addressText'], details)) && (
                    <Fragment>
                      <Field
                        label="Full Address (deprecated)"
                        name={`${namePrefix}location.addressText`}
                        component={FormikField}
                        disabled
                      />
                      <InfoPanel
                        text={
                          <Fragment>
                            <Glyphicon glyph="exclamation-sign" /> Notice: This field
                            containing the full address is temporarily preserved here for
                            informational purposes only and must be updated using the new
                            fields below.
                          </Fragment>
                        }
                      />
                    </Fragment>
                  )
                }
              </LocationDetails>
            )}

            <Field
              name={`${namePrefix}location.address`}
              label="Street address"
              component={FormikField}
              disabled={disabled}
              onLocationChange={onLocationChange}
            />

            <Field
              name={`${namePrefix}location.city`}
              label="City"
              component={FormikField}
              disabled={disabled}
              onLocationChange={onLocationChange}
            />

            <Row>
              <Column md={6}>
                <Field
                  name={`${namePrefix}location.state`}
                  label="State"
                  component={FormikField}
                  disabled={disabled}
                  onLocationChange={onLocationChange}
                />
              </Column>

              <Column md={6}>
                <Field
                  name={`${namePrefix}location.postal`}
                  label="Postal code"
                  component={FormikField}
                  disabled={disabled}
                  onLocationChange={onLocationChange}
                />
              </Column>
            </Row>

            <Field
              name={`${namePrefix}location.country`}
              label="Country"
              component={FormikField}
              type={CountrySelect}
              disabled={disabled}
              onLocationChange={onLocationChange}
            />
          </Panel>
        </Column>

        <Column md={6}>
          <Panel heading="Coordinates">
            <LatLngPickerForm namePrefix={`${namePrefix}geom`} disabled={disabled} />
          </Panel>
        </Column>
      </Row>

      {/* Commented out for WO-564 can be deleted if no objections */}
      {/* <WorksheetBaseQuestion
        name={`${namePrefix}excludedFromPlan`}
        fieldType="checkbox"
        text="Exclude location from this plan"
        disabled={disabled}
      /> */}
    </Block>
  );
};

PlanAppLocationFormFields.propTypes = {
  planAppLocation: PropTypes.object.isRequired,
  namePrefix: PropTypes.string,
  disabled: PropTypes.bool,
};

PlanAppLocationFormFields.defaultProps = {
  namePrefix: '',
  disabled: false,
};

export default PlanAppLocationFormFields;
